import React from 'react';
import { Col, Row } from 'antd/es/grid';

import brandHarrods from 'static/images/brandHarrods.png';
import brandEssilor from 'static/images/brandEssilor.png';
import brandRiveGauche from 'static/images/brandRiveGauche.png';
import brandWW from 'static/images/brandWW.png';
import blandLOfficiel from 'static/images/blandLOfficiel.png';
import brandCreditSuisse from 'static/images/brandCreditSuisse.png';

export const BrandsLogos = () => {
  return (
    <Row type={'flex'} justify={'space-between'} align={'middle'} className={'mt-30 brands'}>
      <Col xs={8} xl={4}>
        <img src={brandHarrods} alt={'Harrods'} className={'brands__item brands__item_harrods'} />
      </Col>
      <Col xs={8} xl={3}>
        <img src={brandEssilor} alt={'Essilor'} className={'brands__item brands__item_essilor'} />
      </Col>
      <Col xs={8} xl={3}>
        <img src={brandRiveGauche} alt={'Rive Gauche'} className={'brands__item brands__item_rg'} />
      </Col>
      <Col xs={8} xl={3}>
        <img src={brandWW} alt={'Volkswagen'} className={'brands__item brands__item_vw'} />
      </Col>
      <Col xs={8} xl={3}>
        <img src={blandLOfficiel} alt={"L'Officiel"} className={'brands__item brands__item_lo'} />
      </Col>
      <Col xs={8} xl={4}>
        <img src={brandCreditSuisse} alt={'Credit Suisse'} className={'brands__item'} />
      </Col>
    </Row>
  );
};
