import React from 'react';
import { Col, Row } from 'antd/es/grid';
import Typography from 'antd/es/typography';
import Icon from 'antd/es/icon';
import 'static/css/index.scss';
import { Button, Container } from '@axmit/clp-library';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BrandsLogos } from 'components/BrandsLogos';
import { LandingCarousel } from 'components/LandingCarousel';
import SEO from 'components/Seo';
import { redirectHelper } from 'helpers/redirect.helper';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

export default ({ data }) => {
  const setImgWrapperHeight = () => {
    const imgHeight = window.innerWidth / 3;
    const sliderImgWrapper = document.getElementsByClassName('gatsby-image-wrapper')[0] as HTMLElement | undefined;
    if (sliderImgWrapper) {
      sliderImgWrapper.style.height = `${imgHeight}px`;
    }
  };

  React.useEffect(() => {
    setImgWrapperHeight();
    window.addEventListener('resize', setImgWrapperHeight);
    return () => window.removeEventListener('resize', setImgWrapperHeight);
  }, []);

  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="landing-wrapper pb-30">
      <SEO />

      <Container>
        <Row type="flex" align="middle" className="mt-30">
          <Col xs={21} sm={12}>
            <Typography.Title className="clp-typography-title clp-typography-title_size_sm mb-25">
              {getTranslates(data.allStrapiClpLandIndex, 'header', language)}
            </Typography.Title>

            <Typography.Text>
              <p className="clp-typography-text clp-typography-text_size_sm mb-25 pr-100 clp-typography-pale">
                {getTranslates(data.allStrapiClpLandIndex, 'content1', language)}
              </p>
              <p className="clp-typography-text clp-typography-text_size_sm mb-50 pr-100 clp-typography-pale">
                {getTranslates(data.allStrapiClpLandIndex, 'content2', language)}
              </p>
            </Typography.Text>

            <Button type="primary" className="pl-20 pr-20 hidden_mob" onClick={redirectHelper.redirectToNewGift}>
              <span>
                {getTranslates(data.allStrapiClpLandIndex, 'btn__submit', language)}
                <Icon type="right" className="ml-20" />
              </span>
            </Button>
          </Col>

          <Col xs={3} sm={12} className="land-img_main-container">
            <div className="land-img land-img_main-desktop">
              <Img fixed={data.MainDesktopPreview.childImageSharp.fixed} alt="Jules" imgStyle={{ objectFit: 'contain' }} />
            </div>

            <div className="land-img land-img_main-mobile-wrapper">
              <Img fixed={data.MainMobilePreview.childImageSharp.fixed} alt="Jules small" imgStyle={{ objectFit: 'contain' }} />
            </div>
          </Col>
        </Row>

        <Row type="flex" align="middle" justify="center" className="down-navigation">
          <Col>
            <Button type="link" onClick={scrollToReview} className="down-navigation__button">
              <Img fixed={data.Down.childImageSharp.fixed} alt="down" />
            </Button>
          </Col>
        </Row>

        <Button
          type="primary"
          className="ant-btn_mob-block mb-50 hidden_desk"
          size="small"
          onClick={redirectHelper.redirectToNewGift}
        >
          <span>
            {getTranslates(data.allStrapiClpLandIndex, 'btn__submit', language)}
            <Icon type="right" className="ml-20" />
          </span>
        </Button>

        <Row type="flex" align="middle" className="hidden_mob" id="review-section">
          <Col md={24} xl={14}>
            <div className="land-img__carousel-preview">
              <Img fluid={data.CarouselPreview.childImageSharp.fluid} alt="Sea" />
            </div>
          </Col>

          <Col md={24} xl={10}>
            <LandingCarousel
              items={[
                {
                  sign: getTranslates(data.allStrapiClpLandIndex, 'review1__sign', language),
                  content: getTranslates(data.allStrapiClpLandIndex, 'review1__content', language)
                },
                {
                  sign: getTranslates(data.allStrapiClpLandIndex, 'review2__sign', language),
                  content: getTranslates(data.allStrapiClpLandIndex, 'review2__content', language)
                },
                {
                  sign: getTranslates(data.allStrapiClpLandIndex, 'review3__sign', language),
                  content: getTranslates(data.allStrapiClpLandIndex, 'review3__content', language)
                },
                {
                  sign: getTranslates(data.allStrapiClpLandIndex, 'review4__sign', language),
                  content: getTranslates(data.allStrapiClpLandIndex, 'review4__content', language)
                }
              ]}
            />
          </Col>
        </Row>

        <div className="mb-100 mt-50">
          <Typography.Title className="clp-typography-title clp-typography-title_size_sm mb-25 mob-text_center" level={3}>
            {getTranslates(data.allStrapiClpLandIndex, 'brands__header', language)}
          </Typography.Title>

          <BrandsLogos />
        </div>
      </Container>
    </div>
  );
};

const scrollToReview = () => {
  document?.getElementById('review-section')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const query = graphql`
  query {
    CarouselPreview: file(relativePath: { eq: "images/sea.jpg" }) {
      childImageSharp {
        fluid(quality: 90, sizes: "(min-width: 720px) 56vw, 720px") {
          ...GatsbyImageSharpFluid
        }
      }
    }
    MainDesktopPreview: file(relativePath: { eq: "images/julesCle.png" }) {
      childImageSharp {
        fixed(width: 440, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    MainMobilePreview: file(relativePath: { eq: "images/julesMobile.png" }) {
      childImageSharp {
        fixed(width: 116, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    Down: file(relativePath: { eq: "images/down.png" }) {
      childImageSharp {
        fixed(height: 9, width: 16, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    allStrapiClpLandIndex {
      edges {
        node {
          header__en
          header__fr
          content1__en
          content1__fr
          content2__en
          content2__fr
          btn__submit__en
          btn__submit__fr
          review1__content__en
          review1__content__fr
          review1__sign__en
          review1__sign__fr
          review2__content__en
          review2__content__fr
          review2__sign__en
          review2__sign__fr
          review3__content__en
          review3__content__fr
          review3__sign__en
          review3__sign__fr
          review4__content__en
          review4__content__fr
          review4__sign__en
          review4__sign__fr
          brands__header__en
          brands__header__fr
        }
      }
    }
  }
`;
